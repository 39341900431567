import { createRouter, createWebHistory } from 'vue-router';

// Define routes with lazy loading for each component
const routes = [
  { path: "/", component: () => import("@/components/AppWelcome.vue") },
  { path: "/:data", component: () => import("@/components/AppWelcome.vue") },
  { path: "/explorer", component: () => import("@/components/AppExplorer.vue") },
  { path: "/explorer/:data", component: () => import("@/components/AppExplorer.vue") },
  { path: "/helpdesk", component: () => import("@/components/AppHelpDesk.vue") },
  { path: "/dashboard", component: () => import("@/components/AppDashboard.vue"), meta: { requiresAuth: true } },
  { path: "/mailbox", component: () => import("@/components/AppMailbox.vue"), meta: { requiresAuth: true } },
  { path: "/mailbox-new", component: () => import("@/components/AppMailboxNew.vue"), meta: { requiresAuth: true } },
];

// Create Vue Router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('address');

  // Check for required authentication
  if (to.meta.requiresAuth && !isAuthenticated) {
    return next('/');
  }

  // Redirect authenticated users away from login page
  if (to.path === '/' && isAuthenticated) {
    return next('/dashboard');
  }

  next();
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
