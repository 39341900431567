import axios from 'axios';

const blockchainClient = axios.create({
  baseURL: process.env.VUE_APP_BLOCKCHAIN_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  get(resource) {
    return blockchainClient.get(resource, {
      auth: {
        username: process.env.VUE_APP_MASTER_USERNAME,
        password: process.env.VUE_APP_MASTER_PASSWORD
      }
    });
  },
  post(resource, data) {
    return blockchainClient.post(resource, data, {
      auth: {
        username: process.env.VUE_APP_MASTER_USERNAME,
        password: process.env.VUE_APP_MASTER_PASSWORD
      }
    });
  },
  put(resource, data) {
    return blockchainClient.put(resource, data, {
      auth: {
        username: process.env.VUE_APP_MASTER_USERNAME,
        password: process.env.VUE_APP_MASTER_PASSWORD
      }
    });
  },
  delete(resource) {
    return blockchainClient.delete(resource, {
      auth: {
        username: process.env.VUE_APP_MASTER_USERNAME,
        password: process.env.VUE_APP_MASTER_PASSWORD
      }
    });
  },
};
