import { io } from "socket.io-client";

class WebSocketConnection {
  constructor(timestamp, account, authenticator, onMessage, onStatusUpdate) {
    this.isManualDisconnect = false;
    this.baseURL = process.env.VUE_APP_SOCKET_URL;
    this.timestamp = timestamp;
    this.account = account;
    this.authenticator = authenticator;
    this.onMessage = onMessage;
    this.onStatusUpdate = onStatusUpdate;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 20;
    this.initBlockIndex(timestamp);
  }

  connectSocket() {
    this.socketIO = io(this.baseURL, {
      query: {
        address: this.account,
        authenticator: this.authenticator,
        timestamp: this.timestamp,
        room: this.account
      },
      autoConnect: false, // Disable autoConnect
      reconnection: false, // Disable automatic reconnection
      transports: ["websocket"],
    });

    this.addEventListeners();
    this.initBlockIndex(this.timestamp);
    this.socketIO.connect();
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  }

  handleBeforeUnload = () => {
    if (this.socketIO) {
      this.socketIO.disconnect();
    }
  }

  initBlockIndex(timestamp) {
    const mailbox_last_message = localStorage.getItem('mailbox_last_message');
    this.timestamp = mailbox_last_message ? mailbox_last_message : timestamp;
  }

  addEventListeners() {
    this.socketIO.on("connect", () => {
      this.onStatusUpdate(true);
      this.reconnectAttempts = 0;
    });

    this.socketIO.on("disconnect", () => {
      this.onStatusUpdate(false);
      this.attemptReconnect();
    });

    this.socketIO.on("new_message", (message) => {
      this.onMessage(message);
    });

    this.socketIO.on("connect_error", () => {
      this.attemptReconnect();
    });
  }

  attemptReconnect() {
    if (this.isManualDisconnect) {
      return;
    }

    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      const delay = Math.pow(2, this.reconnectAttempts) * 1000;
      setTimeout(async () => {
        if (this.socketIO && this.socketIO.connected) {
          return;
        }
        this.reconnectAttempts += 1;
        await this.initBlockIndex();
        this.connectSocket();
      }, delay);
    } else {
      return false;
    }
  }

  async sendMessage(type) {
    await this.initBlockIndex();
    this.socketIO.emit('send_message', {
      address: this.account,
      authenticator: this.authenticator,
      room: this.account,
      message: type,
      timestamp: this.timestamp,
    });
  }

  disconnectSocket() {
    return new Promise((resolve) => {
      if (this.socketIO) {
        this.isManualDisconnect = true;

        // Listen for acknowledgment from the server
        this.socketIO.once('disconnect_ack', () => {
          this.socketIO.disconnect(); // Now disconnect
          this.socketIO = null;
          window.removeEventListener("beforeunload", this.handleBeforeUnload);
          resolve();
        });

        // Notify the server of the intent to disconnect
        this.socketIO.emit('init_disconnect');
      } else {
        resolve();
      }
    });
  }
}

export default {
  createConnection(timestamp, account, authenticator, onMessage, onStatusUpdate) {
    const connection = new WebSocketConnection(timestamp, account, authenticator, onMessage, onStatusUpdate);
    connection.connectSocket(); // Start the connection process
    return connection;
  },
};
