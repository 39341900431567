// plugins/fontawesome.js
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faCircleChevronDown,
    faCircleChevronUp,
    faUser,
    faUsers,
    faFlag,
    faInbox,
    faPaperPlane,
    faFile,
    faTrash,
    faReply,
    faReplyAll,
    faFilter,
    faPenToSquare,
    faWallet,
    faRightFromBracket,
    faEnvelope,
    faAt,
    faBriefcase,
    faX,
    faHome,
    faBook,
    faMobileScreenButton,
    faInfoCircle,
    faArrowRightArrowLeft,
    faCircleRight,
    faCheck,
    faCircleCheck,
    faMagnifyingGlass,
    faAlignLeft,
    faAlignCenter,
    faAlignRight,
    faAlignJustify,
    faRotateLeft,
    faRotateRight,
    faPalette,
    faIndent,
    faListUl,
    faListOl,
    faDollar,
    faShare,
    faXmark,
    faEdit,
    faSquareMinus,
    faSquarePlus,
    faCoins,
    faTrophy,
    faRectangleAd,
    faBell,
    faShield,
    faCircleDollarToSlot,
    faLock,
    faKey,
    faVault,
    faFingerprint,
    faLanguage,
    faHourglassStart,
    faPlus,
    faRightToBracket,
    faTrashArrowUp,
    faBan,
    faFileContract,
    faAddressBook,
    faQrcode,
    faCashRegister,
    faDownload,
    faCopy,
    faCalendar
} from '@fortawesome/free-solid-svg-icons';

import { faFacebookF, faDiscord } from '@fortawesome/free-brands-svg-icons';

library.add(
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faCircleChevronDown,
    faCircleChevronUp,
    faUser,
    faUsers,
    faFlag,
    faInbox,
    faPaperPlane,
    faFile,
    faTrash,
    faReply,
    faReplyAll,
    faFilter,
    faPenToSquare,
    faWallet,
    faRightFromBracket,
    faEnvelope,
    faAt,
    faBriefcase,
    faX,
    faHome,
    faFacebookF,
    faDiscord,
    faBook,
    faMobileScreenButton,
    faInfoCircle,
    faArrowRightArrowLeft,
    faCircleRight,
    faCheck,
    faCircleCheck,
    faMagnifyingGlass,
    faAlignLeft,
    faAlignCenter,
    faAlignRight,
    faAlignJustify,
    faRotateLeft,
    faRotateRight,
    faPalette,
    faIndent,
    faListUl,
    faListOl,
    faDollar,
    faShare,
    faXmark,
    faEdit,
    faSquareMinus,
    faSquarePlus,
    faCoins,
    faTrophy,
    faRectangleAd,
    faBell,
    faShield,
    faCircleDollarToSlot,
    faLock,
    faKey,
    faVault,
    faFingerprint,
    faLanguage,
    faHourglassStart,
    faPlus,
    faRightToBracket,
    faTrashArrowUp,
    faBan,
    faFileContract,
    faAddressBook,
    faQrcode,
    faCashRegister,
    faDownload,
    faCopy,
    faCalendar
);

export default {
    install: (app) => {
        app.component('FontAwesomeIcon', FontAwesomeIcon);
    },
};
