import { createApp } from 'vue';
import App from './App.vue';
import utils from './plugins/utils';
import blockchain from './plugins/blockchain';
import socket from './plugins/socket';
import i18n from './plugins/i18n'
import router from './router';
import fontAwesome from './plugins/fontawesome';
import pwa from './plugins/pwa';
import Vue3TouchEvents from 'vue3-touch-events';
import { capacitorPlugins } from './plugins/capacitorPlugins';

const app = createApp(App);

app.provide('Utils', utils);
app.provide('Blockchain', blockchain);
app.provide('Socket', socket);
app.provide('PWA', pwa);
app.provide('Capacitor', capacitorPlugins);

app.use(router);
app.use(fontAwesome);
app.use(Vue3TouchEvents);
app.use(i18n);

app.mount('#app');
