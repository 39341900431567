export default {
    getWallet(account) {
        return JSON.parse(localStorage.getItem(account));
    },

    formatAmount(amount) {
        return amount.toLocaleString();
    },

    formatAmountInIBX(amount, markets) {
        const decimalPlaces = parseInt((markets?.['decimal']));
        return parseFloat((amount / (10 ** decimalPlaces)).toFixed(decimalPlaces)).toString();
    },

    formatAmountToIBX(amount, markets) {
        const decimalPlaces = parseInt((markets?.['decimal']));
        const multipliedAmount = amount * (10 ** decimalPlaces);
        return Math.floor(multipliedAmount);
    },

    calculateMarketValue(marketData, amount, symbol) {
        return this.formatAmount(amount * marketData[symbol]['price']);
    },

    formatMulti(multi) {
        return multi.replace(';', ' | ');
    },

    formatHTML(html) {
        html = html.replace(/\r?\n/g, "<br>");
        return html;
    },

    minifyHTML(html) {
        // Format HTML
        html = this.formatHTML(html);

        // Remove boundaries, Mime-Version, and common email headers more flexibly
        html = html.replace(/--[\w-]+<br><br>/g, "") // Remove initial and any footer boundaries
                   .replace(/Mime-Version: 1.0<br><br>/gi, "") // Remove Mime-Version
                   .replace(/Content-Type: [\s\S]*?<br><br>/gi, "") // Remove Content-Type headers
                   .replace(/charset=[\s\S]*?<br><br>/gi, "") // Remove charset specifications
                   .replace(/Content-Transfer-Encoding: [\s\S]*?<br><br>/gi, ""); // Remove Content-Transfer-Encoding headers

        // Minify by removing extra spaces and comments
        html = html.trim();
        html = html.replace(/\s+/g, " ").trim();
        html = html.replace(/<!--[\s\S]*?-->/g, "");

        // Replace URLs in plain text with <a> tags
        html = html.replace(/(^|[^<a"])(https?:\/\/[^\s<]+)/g, '$1<a href="$2" target="_blank" style="color: rgb(136, 192, 238)">$2</a>');

        // Remove images containing a specific URL
        html = html.replace(/<img\s+[^>]*?src="https:\/\/node\.inblox\.network\/broadcast\/private\/seen[^>]+>/gi, "");

        // Resize remaining images to 70%
        html = html.replace(/<img\s+([^>]*?)src=["']([^"']+)["']/gi, function(match, attributes, src) {
            if (!/style=/.test(attributes)) {
                attributes += ` style="max-width: 70%; height: auto;"`;
            } else {
                attributes = attributes.replace(/style=["'](.*?)["']/, function(styleMatch, styleContent) {
                    if (!/max-width:\s*70%;/.test(styleContent)) {
                        return `style="${styleContent}; max-width: 70%; height: auto;"`;
                    }
                    return styleMatch;
                });
            }
            return `<img ${attributes} src="${src}"`;
        });

        html = html.replace(/(?:<br>|^)\s*(?:&gt;|>)[^\n]*((<br>\s*(?:&gt;|>)[^\n]*)+)?/g, '');
        html = html.replace(/^(\s*<br>\s*)+/, '');
        html = html.replace(/(\s*<br>\s*)+$/, '');
        html = html.replace('<br><br><br><br>', '<br><br>');
        html = html.replace(/<br\s*\/?>/gi, "\n");

        return html;
    },

    formatTimestamp(display, timestamp) {
        const timestampDate = new Date(timestamp * 1000);
        const now = new Date();

        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        //const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

        const timestampDay = new Date(timestampDate.getFullYear(), timestampDate.getMonth(), timestampDate.getDate());

        const time = timestampDate.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});

        if (display === 'list' && timestampDay.getTime() === today.getTime()) {
            return `${time}`;
        } else {
            if (display === 'list') {
                return timestampDate.toLocaleDateString('en-US');
            } else {
                return timestampDate.toLocaleString('en-US');
            }
        }
    },

    correctInBloXDomain(input) {
        const pattern = /@in[bkntv]lo[abcdefghijklmnopqrstuvwyz]/gi;
        return input.replace(pattern, '@inblox');
    },
};
