<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<style>
body {
  margin: 0 0 max(5vh, env(safe-area-inset-bottom));
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: black;
  color: white;
  height: 100%;
}

/* Margin, Padding, Font Size, Font Style Utility Classes */
/* Margins */
.mr-1 { margin-right: 1px; } .ml-1 { margin-left: 1px; } .mt-1 { margin-top: 1px; } .mb-1 { margin-bottom: 1px; }
.mr-2 { margin-right: 2px; } .ml-2 { margin-left: 2px; } .mt-2 { margin-top: 2px; } .mb-2 { margin-bottom: 2px; }
.mr-3 { margin-right: 3px; } .ml-3 { margin-left: 3px; } .mt-3 { margin-top: 3px; } .mb-3 { margin-bottom: 3px; }
.mr-4 { margin-right: 4px; } .ml-4 { margin-left: 4px; } .mt-4 { margin-top: 4px; } .mb-4 { margin-bottom: 4px; }
.mr-5 { margin-right: 5px; } .ml-5 { margin-left: 5px; } .mt-5 { margin-top: 5px; } .mb-5 { margin-bottom: 5px; }
.mr-6 { margin-right: 6px; } .ml-6 { margin-left: 6px; } .mt-6 { margin-top: 6px; } .mb-6 { margin-bottom: 6px; }
.mr-7 { margin-right: 7px; } .ml-7 { margin-left: 7px; } .mt-7 { margin-top: 7px; } .mb-7 { margin-bottom: 7px; }
.mr-8 { margin-right: 8px; } .ml-8 { margin-left: 8px; } .mt-8 { margin-top: 8px; } .mb-8 { margin-bottom: 8px; }
.mr-9 { margin-right: 9px; } .ml-9 { margin-left: 9px; } .mt-9 { margin-top: 9px; } .mb-9 { margin-bottom: 9px; }
.mr-10 { margin-right: 10px; } .ml-10 { margin-left: 10px; } .mt-10 { margin-top: 10px; } .mb-10 { margin-bottom: 10px; }
.mr-11 { margin-right: 11px; } .ml-11 { margin-left: 11px; } .mt-11 { margin-top: 11px; } .mb-11 { margin-bottom: 11px; }
.mr-12 { margin-right: 12px; } .ml-12 { margin-left: 12px; } .mt-12 { margin-top: 12px; } .mb-12 { margin-bottom: 12px; }
.mr-13 { margin-right: 13px; } .ml-13 { margin-left: 13px; } .mt-13 { margin-top: 13px; } .mb-13 { margin-bottom: 13px; }
.mr-14 { margin-right: 14px; } .ml-14 { margin-left: 14px; } .mt-14 { margin-top: 14px; } .mb-14 { margin-bottom: 14px; }
.mr-15 { margin-right: 15px; } .ml-15 { margin-left: 15px; } .mt-15 { margin-top: 15px; } .mb-15 { margin-bottom: 15px; }
.mr-16 { margin-right: 16px; } .ml-16 { margin-left: 16px; } .mt-16 { margin-top: 16px; } .mb-16 { margin-bottom: 16px; }
.mr-17 { margin-right: 17px; } .ml-17 { margin-left: 17px; } .mt-17 { margin-top: 17px; } .mb-17 { margin-bottom: 17px; }
.mr-18 { margin-right: 18px; } .ml-18 { margin-left: 18px; } .mt-18 { margin-top: 18px; } .mb-18 { margin-bottom: 18px; }
.mr-19 { margin-right: 19px; } .ml-19 { margin-left: 19px; } .mt-19 { margin-top: 19px; } .mb-19 { margin-bottom: 19px; }
.mr-20 { margin-right: 20px; } .ml-20 { margin-left: 20px; } .mt-20 { margin-top: 20px; } .mb-20 { margin-bottom: 20px; }
.mr-21 { margin-right: 21px; } .ml-21 { margin-left: 21px; } .mt-21 { margin-top: 21px; } .mb-21 { margin-bottom: 21px; }
.mr-22 { margin-right: 22px; } .ml-22 { margin-left: 22px; } .mt-22 { margin-top: 22px; } .mb-22 { margin-bottom: 22px; }
.mr-23 { margin-right: 23px; } .ml-23 { margin-left: 23px; } .mt-23 { margin-top: 23px; } .mb-23 { margin-bottom: 23px; }
.mr-24 { margin-right: 24px; } .ml-24 { margin-left: 24px; } .mt-24 { margin-top: 24px; } .mb-24 { margin-bottom: 24px; }
.mr-25 { margin-right: 25px; } .ml-25 { margin-left: 25px; } .mt-25 { margin-top: 25px; } .mb-25 { margin-bottom: 25px; }
.mr-26 { margin-right: 26px; } .ml-26 { margin-left: 26px; } .mt-26 { margin-top: 26px; } .mb-26 { margin-bottom: 26px; }
.mr-27 { margin-right: 27px; } .ml-27 { margin-left: 27px; } .mt-27 { margin-top: 27px; } .mb-27 { margin-bottom: 27px; }
.mr-28 { margin-right: 28px; } .ml-28 { margin-left: 28px; } .mt-28 { margin-top: 28px; } .mb-28 { margin-bottom: 28px; }
.mr-29 { margin-right: 29px; } .ml-29 { margin-left: 29px; } .mt-29 { margin-top: 29px; } .mb-29 { margin-bottom: 29px; }
.mr-30 { margin-right: 30px; } .ml-30 { margin-left: 30px; } .mt-30 { margin-top: 30px; } .mb-30 { margin-bottom: 30px; }
.mr-50 { margin-right: 50px; } .ml-50 { margin-left: 50px; } .mt-50 { margin-top: 50px; } .mb-50 { margin-bottom: 50px; }

/* Paddings */
.pr-1 { padding-right: 1px; } .pl-1 { padding-left: 1px; } .pt-1 { padding-top: 1px; } .pb-1 { padding-bottom: 1px; }
.pr-2 { padding-right: 2px; } .pl-2 { padding-left: 2px; } .pt-2 { padding-top: 2px; } .pb-2 { padding-bottom: 2px; }
.pr-3 { padding-right: 3px; } .pl-3 { padding-left: 3px; } .pt-3 { padding-top: 3px; } .pb-3 { padding-bottom: 3px; }
.pr-4 { padding-right: 4px; } .pl-4 { padding-left: 4px; } .pt-4 { padding-top: 4px; } .pb-4 { padding-bottom: 4px; }
.pr-5 { padding-right: 5px; } .pl-5 { padding-left: 5px; } .pt-5 { padding-top: 5px; } .pb-5 { padding-bottom: 5px; }
.pr-6 { padding-right: 6px; } .pl-6 { padding-left: 6px; } .pt-6 { padding-top: 6px; } .pb-6 { padding-bottom: 6px; }
.pr-7 { padding-right: 7px; } .pl-7 { padding-left: 7px; } .pt-7 { padding-top: 7px; } .pb-7 { padding-bottom: 7px; }
.pr-8 { padding-right: 8px; } .pl-8 { padding-left: 8px; } .pt-8 { padding-top: 8px; } .pb-8 { padding-bottom: 8px; }
.pr-9 { padding-right: 9px; } .pl-9 { padding-left: 9px; } .pt-9 { padding-top: 9px; } .pb-9 { padding-bottom: 9px; }
.pr-10 { padding-right: 10px; } .pl-10 { padding-left: 10px; } .pt-10 { padding-top: 10px; } .pb-10 { padding-bottom: 10px; }
.pr-11 { padding-right: 11px; } .pl-11 { padding-left: 11px; } .pt-11 { padding-top: 11px; } .pb-11 { padding-bottom: 11px; }
.pr-12 { padding-right: 12px; } .pl-12 { padding-left: 12px; } .pt-12 { padding-top: 12px; } .pb-12 { padding-bottom: 12px; }
.pr-13 { padding-right: 13px; } .pl-13 { padding-left: 13px; } .pt-13 { padding-top: 13px; } .pb-13 { padding-bottom: 13px; }
.pr-14 { padding-right: 14px; } .pl-14 { padding-left: 14px; } .pt-14 { padding-top: 14px; } .pb-14 { padding-bottom: 14px; }
.pr-15 { padding-right: 15px; } .pl-15 { padding-left: 15px; } .pt-15 { padding-top: 15px; } .pb-15 { padding-bottom: 15px; }
.pr-16 { padding-right: 16px; } .pl-16 { padding-left: 16px; } .pt-16 { padding-top: 16px; } .pb-16 { padding-bottom: 16px; }
.pr-17 { padding-right: 17px; } .pl-17 { padding-left: 17px; } .pt-17 { padding-top: 17px; } .pb-17 { padding-bottom: 17px; }
.pr-18 { padding-right: 18px; } .pl-18 { padding-left: 18px; } .pt-18 { padding-top: 18px; } .pb-18 { padding-bottom: 18px; }
.pr-19 { padding-right: 19px; } .pl-19 { padding-left: 19px; } .pt-19 { padding-top: 19px; } .pb-19 { padding-bottom: 19px; }
.pr-20 { padding-right: 20px; } .pl-20 { padding-left: 20px; } .pt-20 { padding-top: 20px; } .pb-20 { padding-bottom: 20px; }
.pr-21 { padding-right: 21px; } .pl-21 { padding-left: 21px; } .pt-21 { padding-top: 21px; } .pb-21 { padding-bottom: 21px; }
.pr-22 { padding-right: 22px; } .pl-22 { padding-left: 22px; } .pt-22 { padding-top: 22px; } .pb-22 { padding-bottom: 22px; }
.pr-23 { padding-right: 23px; } .pl-23 { padding-left: 23px; } .pt-23 { padding-top: 23px; } .pb-23 { padding-bottom: 23px; }
.pr-24 { padding-right: 24px; } .pl-24 { padding-left: 24px; } .pt-24 { padding-top: 24px; } .pb-24 { padding-bottom: 24px; }
.pr-25 { padding-right: 25px; } .pl-25 { padding-left: 25px; } .pt-25 { padding-top: 25px; } .pb-25 { padding-bottom: 25px; }
.pr-26 { padding-right: 26px; } .pl-26 { padding-left: 26px; } .pt-26 { padding-top: 26px; } .pb-26 { padding-bottom: 26px; }
.pr-27 { padding-right: 27px; } .pl-27 { padding-left: 27px; } .pt-27 { padding-top: 27px; } .pb-27 { padding-bottom: 27px; }
.pr-28 { padding-right: 28px; } .pl-28 { padding-left: 28px; } .pt-28 { padding-top: 28px; } .pb-28 { padding-bottom: 28px; }
.pr-29 { padding-right: 29px; } .pl-29 { padding-left: 29px; } .pt-29 { padding-top: 29px; } .pb-29 { padding-bottom: 29px; }
.pr-30 { padding-right: 30px; } .pl-30 { padding-left: 30px; } .pt-30 { padding-top: 30px; } .pb-30 { padding-bottom: 30px; }

/* Font Sizes */
.font-10 { font-size: 10px; }
.font-11 { font-size: 11px; }
.font-12 { font-size: 12px; }
.font-13 { font-size: 13px; }
.font-14 { font-size: 14px; }
.font-15 { font-size: 15px; }
.font-16 { font-size: 16px; }
.font-17 { font-size: 17px; }
.font-18 { font-size: 18px; }
.font-19 { font-size: 19px; }
.font-20 { font-size: 20px; }
.font-21 { font-size: 21px; }
.font-22 { font-size: 22px; }
.font-23 { font-size: 23px; }
.font-24 { font-size: 24px; }
.font-25 { font-size: 25px; }
.font-26 { font-size: 26px; }
.font-27 { font-size: 27px; }
.font-28 { font-size: 28px; }
.font-29 { font-size: 29px; }
.font-30 { font-size: 30px; }


/* Text Style and Alignment Utility Classes */
.bold { font-weight: bold; }
.italic { font-style: italic; }
.underline { text-decoration: underline; }

/* Text alignment */
.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

/* Cursor behavior */
.pointer { cursor: pointer; }

</style>
