import { Capacitor } from '@capacitor/core';
import { Clipboard } from '@capacitor/clipboard';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Network } from '@capacitor/network';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';

export const capacitorPlugins = {
  core: Capacitor,
  clipboard: Clipboard,
  camera: Camera,
  cameraResultType: CameraResultType,
  network: Network,
  localNotifications: LocalNotifications,
  pushNotifications: PushNotifications,
};