import { createI18n } from 'vue-i18n'
import de from '../locales/de.json'
import en from '../locales/en.json'
import es from '../locales/es.json'
import fr from '../locales/fr.json'
import hi from '../locales/hi.json'
import id from '../locales/id.json'
import it from '../locales/it.json'
import ja from '../locales/ja.json'
import ko from '../locales/ko.json'
import nl from '../locales/nl.json'
import pt from '../locales/pt.json'
import sv from '../locales/sv.json'
import da from '../locales/da.json'
import ru from '../locales/ru.json'
import th from '../locales/th.json'
import zh_cn from '../locales/zh-cn.json'
import zh_tw from '../locales/zh-tw.json'
import ar from '../locales/ar.json'

const locale = localStorage.getItem('locale')

if(!locale) {
    localStorage.setItem('locale', 'en');
}

const i18n = createI18n({
    locale: !locale ? 'en' : locale,
    fallbackLocale: 'en',
    messages: {
        de,
        en,
        es,
        fr,
        hi,
        id,
        it,
        ja,
        ko,
        nl,
        pt,
        sv,
        da,
        ru,
        th,
        zh_cn,
        zh_tw,
        ar
    }
})

export default i18n
